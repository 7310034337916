@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  input[type=text].form-control,
  input[type=number].form-control,
  input[type=url].form-control,
  textarea.form-control {
    @apply shadow-sm focus:ring-mdarkgreen focus:border-mdarkgreen block w-full sm:text-sm border-gray-700 rounded-md;
  }
  input[type=text].form-control:disabled,
  input[type=number].form-control:disabled,
  input[type=url].form-control:disabled,
  textarea.form-control:disabled {
    @apply bg-gray-200;
  }
  input[type=file].form-control {
    @apply block cursor-pointer w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-400 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-mgreen focus:outline-none
  }
  input[type=file].form-control::file-selector-button {
    @apply py-1.5 px-3 -my-1.5 -mx-3 border-solid border-0 border-inherit rounded-none pointer-events-none text-gray-700 bg-gray-200;
    margin-inline-end: .75rem;
    border-inline-end-width: 1px;
  }
  input[type=checkbox].form-control {
    @apply focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded;
  }
  select.form-control {
    @apply block w-full pl-3 pr-10 py-2 text-base border-gray-700 focus:outline-none focus:ring-mdarkgreen focus:border-mgreen sm:text-sm rounded-md;
  }
}

.react-select__control--is-focused,
.react-select__control:hover {
  border-color: theme('colors.mgreen') ! important;
  box-shadow: 0 0 0 1px theme('colors.mgreen') ! important;
}
